export function transformUrl(url: string): string {
  url = url.trim();

  url = url.replace(/^https?:?\/\//i, "");
  url = url.replace(/^www\.?/i, "");

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }

  return url;
}

export function capitalizeEveryWord(str: string) {
  return str
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}
