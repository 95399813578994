import { useTranslate } from "@refinedev/core";
import { StepProps, Steps, Typography } from "antd";
import React from "react";
import FirstStep from "./FirstStep";
import SelectSubscription from "./SelectSubscription";

export default function CreateTeam() {
  const [newTeam, setNewTeam] = React.useState<any>();
  const [currentStep, setCurrentStep] = React.useState(0);
  const [choosenPlan, setChoosenPlan] = React.useState<any>();

  const translate = useTranslate();
  const items: StepProps[] = [
    {
      description: (
        <FirstStep
          disabled={currentStep !== 0}
          handleNext={(team) => {
            setCurrentStep(1);
            setNewTeam(team);
          }}
        />
      ),
      subTitle: (
        <Typography.Text
          disabled={currentStep !== 0}
          style={{ fontWeight: "500" }}
        >
          {translate("teams.name_your_team") + ":"}
        </Typography.Text>
      ),
      title: <Typography.Title type="secondary">1</Typography.Title>,
    },
    {
      title: (
        <Typography.Title disabled={currentStep < 1} type="secondary">
          2
        </Typography.Title>
      ),
      subTitle: (
        <Typography.Text
          disabled={currentStep < 1}
          style={{ fontWeight: "500" }}
        >
          {translate("teams.choose_subscription")}
        </Typography.Text>
      ),
      description: (
        <SelectSubscription
          handleOnChange={(item) => setChoosenPlan(item)}
          disabled={currentStep < 1}
          team={newTeam}
          handleNext={() => setCurrentStep((prev) => prev + 1)}
          handlePrev={() => setCurrentStep((prev) => prev - 1)}
        />
      ),
    },
  ];

  return (
    <Steps
      style={{ width: "80%" }}
      direction="vertical"
      current={currentStep}
      items={
        choosenPlan && choosenPlan?.price
          ? [
              ...items,
              //   {
              //     title: (
              //       <Typography.Title disabled={currentStep < 1} type="secondary">
              //         3
              //       </Typography.Title>
              //     ),

              //     description: (
              //       <Space direction="vertical">
              //         <Button>Continue to payment</Button>
              //         <Button type="primary">Create Team</Button>
              //       </Space>
              //     ),
              //   },
            ]
          : items
      }
    ></Steps>
  );
}
