import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";

import {
  useDelete,
  useNavigation,
  useShow,
  useTranslate,
} from "@refinedev/core";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "@refinedev/antd";
import {
  Button,
  Card,
  Collapse,
  Drawer,
  Flex,
  Form,
  Input,
  Popconfirm,
  Space,
  Spin,
} from "antd";
import { CollapseProps } from "antd/lib";
import dayjs from "dayjs";
import { SingleElementForm, Text } from "../../../components";
import SinglePhase from "../../../components/shop/single-phase";
import { useUser } from "../../../contexts/ContextProvider";
const SubscriptionProductsShow: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [activeForm, setActiveForm] = useState<"title" | "description">();
  const { currentTeam } = useUser();
  const { list } = useNavigation();
  const { mutate: deleteMutation } = useDelete();

  const { queryResult } = useShow({
    resource: "subscription_products",
  });

  const { formProps, form } = useForm({
    action: "edit",
    redirect: false,
    autoSave: {
      enabled: true,
      invalidateOnUnmount: true,
    },
    mutationMode: "pessimistic",
  });
  const translate = useTranslate();

  const closeModal = () => {
    list("subscription_products");
  };

  const { data, isLoading, isError } = queryResult;

  const { id, title, description, phases, created_at } = data?.data ?? {};

  const handlePhaseDelete = useCallback(
    (index: number) => {
      const changedPhase = phases.filter(
        (item, indexFilter) => index != indexFilter
      );
      form.setFieldValue("phases", changedPhase);
      form.submit();
    },
    [form, phases]
  );
  const handleChangesOnPhases = useCallback(
    (phase: any, index: number) => {
      const changedPhase = phases.map((item, indexPhase) => {
        if (indexPhase == index) {
          item = phase;
        }
        return item;
      });

      form.setFieldValue("phases", changedPhase);
      form.submit();
    },
    [form, phases]
  );
  const items: CollapseProps["items"] = useMemo(
    () =>
      phases?.map((item: any, index: number) => {
        return {
          key: (index + 1).toString(), // key should be a string
          label: translate("product_items.phase_nr") + (index + 1),
          children: (
            <SinglePhase
              handleChange={(phase) => handleChangesOnPhases(phase, index)}
              handlePhaseDelete={() => handlePhaseDelete(index)}
              data={item}
            />
          ),
        };
      }),
    [handleChangesOnPhases, handlePhaseDelete, phases, translate]
  );

  if (isError) {
    closeModal();
    return null;
  }

  if (isLoading && !currentTeam?.account_id) {
    return (
      <Drawer
        open
        styles={{
          body: {
            background: "#f5f5f5",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        }}
        className="entityDrawer"
      >
        <Spin />
      </Drawer>
    );
  }

  const handleAddNewPhase = () => {
    const newPhase = [
      ...phases,
      {
        key: 1,
        name: "Best deal ever!",
        invoice: {
          unit: "month",
          value: 3,
        },
        duration: {
          to: {
            unit: "week",
            value: 1,
          },
          from: {
            unit: "day",
            value: 1,
          },
          invoice: {
            unit: "day",
            value: 1,
          },
        },
        product_items: [],
      },
    ];
    form.setFieldValue("phases", newPhase);
    form.submit();
  };
  return (
    <>
      <Drawer
        open
        onClose={() => closeModal()}
        className="entityDrawer"
        title={translate("subscription_products.show.title")}
      >
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          <Card
            title={
              <>
                <Text>{translate("subscription_products.show.basic")}</Text>
              </>
            }
          >
            <SingleElementForm
              state={
                activeForm && activeForm === "title"
                  ? "form"
                  : title
                  ? "view"
                  : "empty"
              }
              itemProps={{
                name: "title",
                label: translate("subscription_products.fields.title"),
                initialValue: title,
              }}
              useFormProps={{
                resource: "subscription_products",
                id: id,
              }}
              view={<Text>{title}</Text>}
              onClick={() => setActiveForm("title")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
            >
              <Input />
            </SingleElementForm>
            <SingleElementForm
              state={
                activeForm && activeForm === "description"
                  ? "form"
                  : description
                  ? "view"
                  : "empty"
              }
              itemProps={{
                name: "description",
                label: translate("subscription_products.fields.description"),
                initialValue: description,
              }}
              useFormProps={{
                resource: "subscription_products",
                id: id,
              }}
              view={<Text>{description}</Text>}
              onClick={() => setActiveForm("description")}
              onUpdate={() => setActiveForm(undefined)}
              onCancel={() => setActiveForm(undefined)}
            >
              <Input.TextArea rows={6} />
            </SingleElementForm>
          </Card>
          <Card
            title={
              <>
                <Text>{translate("subscription_products.show.phases")}</Text>
              </>
            }
            actions={[
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddNewPhase}
              >
                {translate("subscription_products.list.add")}
              </Button>,
            ]}
          >
            <Form {...formProps}>
              <Form.Item name={"phases"} noStyle>
                <Input type="hidden" />
              </Form.Item>
              <Collapse
                items={items}
                defaultActiveKey={["1"]}
                expandIconPosition="end"
                bordered={false}
              />
            </Form>
          </Card>
          <Flex justify="space-between">
            <Text className="ant-text tertiary">
              {translate("subscription_products.fields.created_at")}{" "}
              {dayjs(created_at).format(translate("page.settings.date_format"))}{" "}
              {translate("page.settings.clock")}
            </Text>

            <Popconfirm
              title={translate(
                "subscription_products.buttons.delete.title_single"
              )}
              description={translate(
                "subscription_products.buttons.delete.description_single"
              )}
              onConfirm={() => {
                if (id) {
                  deleteMutation(
                    {
                      id,
                      resource: "subscription_products",
                    },
                    {
                      onSuccess: () => closeModal(),
                    }
                  );
                }
              }}
              okText={translate("buttons.yes", "Yes")}
              cancelText={translate("buttons.no", "No")}
            >
              <Button type="link" danger icon={<DeleteOutlined />}>
                {translate("subscription_products.buttons.delete.title_single")}
              </Button>
            </Popconfirm>
          </Flex>
        </Space>
      </Drawer>
      {children}
    </>
  );
};
export default SubscriptionProductsShow;
