import { useForm } from "@refinedev/antd";
import { useNavigation, useTranslate } from "@refinedev/core";
import { DatePicker, Form, Modal, Select } from "antd";
import dayjs from "dayjs";
import React, { PropsWithChildren } from "react";
import ContactAutoSelect from "../../../components/contacts/ContactAutoSelect";
import { useUser } from "../../../contexts/ContextProvider";
import { useSubscriptionProductSelect } from "../../../hooks/useSubscriptionProductSelect";
import { calculateStartandEndDate } from "../../../utility/shop";

const SubscriptionCreate: React.FC<PropsWithChildren> = ({ children }) => {
  const translate = useTranslate();
  const { currentTeam } = useUser();
  const { list } = useNavigation();

  const { queryResult: productResult, selectProps: phaseSelectProp } =
    useSubscriptionProductSelect();

  const { formProps, saveButtonProps, onFinish } = useForm({
    resource: "subscriptions",
    action: "create",
    redirect: "list",
  });
  return (
    <>
      <Modal
        open
        maskClosable={false}
        title={translate("subscriptions.create.title")}
        onCancel={() => {
          list("subscriptions", "replace");
        }}
        okText={translate("buttons.save")}
        okButtonProps={{
          ...saveButtonProps,
        }}
        width={760}
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={({ startDate: from, ...values }: any) => {
            const startDate = dayjs(from);
            const choosenProd = productResult.data?.data.find((item) => {
              return item.id == values.subscription_product;
            });
            const phases = choosenProd?.phases?.map((phase) => {
              let from = phase.duration?.from;
              let to = phase.duration?.to;

              if (typeof from == "object") {
                from = calculateStartandEndDate({
                  unit: from.unit,
                  startDate,
                  value: from.value,
                  obj: from,
                });
              }
              if (typeof to == "object") {
                to = calculateStartandEndDate({
                  unit: to.unit,
                  startDate,
                  value: to.value,
                  obj: to,
                });
              }
              return { ...phase, duration: { from, to } };
            });

            onFinish({
              title: choosenProd?.title,
              contact: values.contact,
              phases,
              status: "active",
              account: currentTeam?.account_id,
            });
          }}
        >
          <ContactAutoSelect label={translate("subscriptions.fields.user")} />
          <Form.Item
            name={"subscription_product"}
            label={translate("subscriptions.fields.subscription_product")}
            rules={[{ required: true }]}
          >
            <Select
              style={{ width: "100%" }}
              {...phaseSelectProp}
              options={productResult.data?.data.map((item) => ({
                value: item.id,
                label: item.title,
              }))}
            />
          </Form.Item>
          <Form.Item
            label={translate("subscriptions.fields.start_date")}
            name="startDate"
            initialValue={dayjs()}
          >
            <DatePicker format={{ format: translate("global.date_format") }} />
          </Form.Item>
        </Form>
      </Modal>
      {children}
    </>
  );
};

export default SubscriptionCreate;
