import { List } from "@refinedev/antd";
import { Table } from "antd";
import { PropsWithChildren, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { useTranslate } from "@refinedev/core";

const TeamMembersList: React.FC<PropsWithChildren> = ({ children }) => {
  const { teamMembers } = useUser();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const translate = useTranslate();

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  return (
    <List title={translate("teams.members.title")}>
      <Table
        dataSource={teamMembers}
        rowClassName={"clickableRow"}
        rowSelection={rowSelection}
        columns={[
          {
            title: translate("teams.members.name"),
            dataIndex: "name",
            key: "name",
          },
          {
            title: translate("teams.members.account_role"),
            dataIndex: "account_role",
            key: "account_role",
          },
          {
            title: translate("teams.members.email"),
            dataIndex: "email",
            key: "email",
          },
        ]}
        rowKey={(record, index) => record.account_id + "" + index}
      />
    </List>
  );
};

export default TeamMembersList;
