import { useTranslate } from "@refinedev/core";
import { Button, Form } from "antd";
import CustomInput from "../form/customInput";

type Props = {
  handleNext: (data) => void;
  disabled?: boolean;
};
export default function FirstStep({ handleNext, disabled }: Props) {
  const translate = useTranslate();
  const onFinish = async (values: any) => {
    handleNext(values.name);
  };
  return (
    <Form onFinish={onFinish} disabled>
      <Form.Item
        name="name"
        rules={[
          { required: true, message: translate("validation.field_required") },
        ]}
      >
        <CustomInput
          disabled={disabled}
          placeholder={translate("global.enter_text_here")}
          label={translate("teams.registration_label")}
        />
      </Form.Item>
      <Form.Item>
        <Button disabled={disabled} type="primary" htmlType="submit">
          {translate("buttons.next")}
        </Button>
      </Form.Item>
    </Form>
  );
}
