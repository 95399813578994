import { useList } from "@refinedev/core";

import React, { PropsWithChildren } from "react";
import { useUser } from "../../contexts/ContextProvider";
import { usePipelineContext } from "../../contexts/PipelineProvider";
import { sortPipelines } from "../../utility/pipelines";

export default function Layout({
  children,
}: PropsWithChildren & { resource?: string; action?: string }) {
  const { pipelines, setPipelines, rawPipelines, setRawPipelines } =
    usePipelineContext();

  const { currentTeam } = useUser();
  const { data } = useList({
    resource: "pipelines",
    liveMode: "auto",
    pagination: { mode: "off" },
    sorters: [{ field: "order_list", order: "asc" }],
    filters: [
      {
        field: "account",
        value:
          currentTeam?.account_id ?? "00000000-0000-0000-0000-000000000000",
        operator: "eq",
      },
    ],
  });
  const memoizedPipelined = React.useMemo(
    () => (data?.data ? sortPipelines(data?.data) : undefined),
    [data?.data]
  );
  React.useEffect(() => {
    const newPipelines = memoizedPipelined?.filter(
      (item) =>
        // !pipelines.length &&
        !pipelines?.find(
          (pipe) => pipe.list === "/:teamSlug/pipelines/show/" + item.id
        )
    );

    if (
      (newPipelines ||
        JSON.stringify(rawPipelines) !== JSON.stringify(memoizedPipelined)) &&
      memoizedPipelined
    ) {
      setPipelines(() => [
        ...memoizedPipelined.map((item) => ({
          name: item.name,
          list: "/:teamSlug/pipelines/show/" + item.id,
          // create: "/pipelines/create",
          // edit: "/pipelines/edit" + item.id,
          identifier: item.name + `_${item.id}` ?? "Pipeline" + item.id,
          meta: { parent: "pipelines-menu", label: item.name },
        })),
      ]);
      setRawPipelines(memoizedPipelined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedPipelined]);

  return <>{children}</>;
}
