import React, { PropsWithChildren, useState } from "react";

import { useForm } from "@refinedev/antd";
import { useTranslate } from "@refinedev/core";
import { Button, Flex, Form, Space, Steps, Typography } from "antd";
import { StepProps } from "antd/lib";
import { useNavigate } from "react-router-dom";
import { Images } from "../../components/Images";
import CustomInput from "../../components/form/customInput";
import useTeam from "../../hooks/useTeam";
import styles from "./style.module.css";

const AccountSetup: React.FC<PropsWithChildren> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [fnameHasValue, setFnameHasValue] = useState(false);
  const [lnameHasValue, setLnameHasValue] = useState(false);
  const [cnameHasValue, setCnameHasValue] = useState(false);
  const [pnameHasValue, setPnameHasValue] = useState(false);
  const { personalAccount, updateTeam } = useTeam();
  const navigate = useNavigate();
  const translate = useTranslate();

  const { formProps, form } = useForm({
    redirect: false,
    autoSave: {
      enabled: false,
    },
    warnWhenUnsavedChanges: false,
    mutationMode: "pessimistic",
  });
  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSaveAccount = async () => {
    const formInput = form.getFieldsValue(true);

    if (formInput) {
      const data = await updateTeam(personalAccount?.account_id, {
        name: `${formInput.firstname} ${formInput.lastname}`,
        public_metadata: {
          firstname: formInput.firstname,
          lastname: formInput.lastname,
          country: formInput.country,
          initialSetup: true,
        },
      });
      if (data?.account_id) {
        //
        navigate("/team/create");
      }
    }
  };
  const items: StepProps[] = [
    {
      title: <Typography.Title type="secondary">1</Typography.Title>,
      subTitle: (
        <Typography.Text style={{ fontWeight: "500" }}>
          Your full name:
        </Typography.Text>
      ),
      description: (
        <>
          <Flex gap={12}>
            <Form.Item
              name={"firstname"}
              rules={[
                {
                  required: true,
                  message: translate("validation.field_required"),
                },
              ]}
            >
              <CustomInput
                placeholder="Firstname*"
                disabled={currentStep != 0}
                onChange={(e: any) => {
                  setFnameHasValue(
                    e.target.value != undefined &&
                      e.target.value != "" &&
                      e.target.value != null
                      ? true
                      : false
                  );
                }}
              />
            </Form.Item>
            <Form.Item
              name={"lastname"}
              rules={[
                {
                  required: true,
                  message: translate("validation.field_required"),
                },
              ]}
            >
              <CustomInput
                placeholder="Lastname*"
                disabled={currentStep != 0}
                onChange={(e: any) => {
                  setLnameHasValue(
                    e.target.value != undefined &&
                      e.target.value != "" &&
                      e.target.value != null
                      ? true
                      : false
                  );
                }}
              />
            </Form.Item>
          </Flex>
          {currentStep === 0 && (
            <Button
              type="primary"
              onClick={() => next()}
              disabled={!fnameHasValue || !lnameHasValue}
            >
              Next
            </Button>
          )}
        </>
      ),

      disabled: currentStep != 0,
    },
    {
      title: (
        <Typography.Title disabled={currentStep < 1} type="secondary">
          2
        </Typography.Title>
      ),
      subTitle: (
        <Typography.Text style={{ fontWeight: "500" }}>
          Your Company Name:
        </Typography.Text>
      ),
      description: (
        <>
          <Form.Item
            name={"company"}
            rules={[
              {
                required: true,
                message: translate("validation.field_required"),
              },
            ]}
          >
            <CustomInput
              placeholder="Company Name"
              disabled={currentStep != 1}
              onChange={(e: any) =>
                setCnameHasValue(
                  e.target.value != undefined &&
                    e.target.value != "" &&
                    e.target.value != null
                    ? true
                    : false
                )
              }
            />
          </Form.Item>
          {currentStep === 1 && (
            <Space>
              <Button onClick={() => prev()}>Prev</Button>
              <Button
                type="primary"
                onClick={() => next()}
                disabled={!cnameHasValue}
              >
                Next
              </Button>
            </Space>
          )}
        </>
      ),
      disabled: currentStep != 1,
    },
    {
      title: (
        <Typography.Title disabled={currentStep < 1} type="secondary">
          3
        </Typography.Title>
      ),
      subTitle: (
        <Typography.Text style={{ fontWeight: "500" }}>
          Select your country:
        </Typography.Text>
      ),
      description: (
        <>
          <Form.Item
            name={"country"}
            rules={[
              {
                required: true,
                message: translate("validation.field_required"),
              },
            ]}
          >
            <CustomInput
              placeholder="Country"
              label="Write the name of the country"
              disabled={currentStep != 2}
              onChange={(e: any) =>
                setPnameHasValue(
                  e.target.value != undefined &&
                    e.target.value != "" &&
                    e.target.value != null
                    ? true
                    : false
                )
              }
            />
          </Form.Item>
          {currentStep === 2 && (
            <Space>
              <Button onClick={() => prev()}>Prev</Button>
              <Button
                type="primary"
                onClick={() => handleSaveAccount()}
                disabled={!pnameHasValue}
              >
                Save Account
              </Button>
            </Space>
          )}
        </>
      ),
      disabled: currentStep != 2,
    },
  ];

  return (
    <>
      <Flex className={styles.container}>
        <div className={styles.stepperContainer}>
          <Form {...formProps} form={form}>
            <Steps
              style={{ width: "80%" }}
              direction="vertical"
              current={currentStep}
              items={items}
            />
          </Form>
        </div>
        <div className={styles.imgContainer}>
          <img src={Images.TeamCover} />
        </div>
        <div className={styles.titleStrip}>
          <Typography.Title>{translate("account.setup")}</Typography.Title>
        </div>
      </Flex>
      {children}
    </>
  );
};
export default AccountSetup;
