import {
  ApartmentOutlined,
  FileOutlined,
  TagOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  CanAccess,
  useLink,
  useMenu,
  useNavigation,
  useTranslate,
} from "@refinedev/core";
import { Layout, Menu } from "antd";
import { PropsWithChildren, useState } from "react";
import { useUser } from "../../contexts/ContextProvider";
const SettingsLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { list } = useNavigation();
  const { selectedKey, defaultOpenKeys } = useMenu();
  const { currentTeam } = useUser();
  const translate = useTranslate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { Sider, Content } = Layout;
  const Link = useLink();

  const menuItems = [
    {
      name: "team",
      list: `/${currentTeam.slug}/settings/team"`,
      meta: {
        label: translate("settings.team"),
      },
      label: translate("settings.team"),
      route: `/${currentTeam.slug}/settings/team`,
      canCreate: false,
      canEdit: false,
      canShow: false,
      key: "/settings/team",
      children: [],
      icon: <UserOutlined />,
    },
    {
      name: "pipelines",
      list: `/${currentTeam.slug}/settings/pipelines`,
      meta: {
        label: translate("pipelines.pipelines"),
      },
      label: translate("pipelines.pipelines"),
      route: `/${currentTeam.slug}/settings/pipelines`,
      canCreate: false,
      canEdit: false,
      canShow: false,
      key: "/settings/pipelines",
      children: [],
      icon: <ApartmentOutlined />,
    },
    {
      name: "groups",
      list: `/${currentTeam.slug}/settings/groups`,
      meta: {
        label: translate("groups.groups"),
      },
      label: translate("groups.groups"),
      route: `/${currentTeam.slug}/settings/groups`,
      canCreate: true,
      canEdit: true,
      canShow: true,
      key: "/settings/groups",
      children: [],
      icon: <TagOutlined />,
    },
    {
      name: "files",
      list: `/${currentTeam.slug}/settings/files`,
      meta: {
        label: translate("files.files"),
      },
      label: translate("files.files"),
      route: `/${currentTeam.slug}/settings/files`,
      canCreate: true,
      canEdit: true,
      canShow: true,
      key: "/settings/files",
      children: [],
      icon: <FileOutlined />,
    },
  ];

  const handleMenuClick = (e) => {
    setSelectedIndex(parseInt(e.key, 10));
  };

  return (
    <Layout>
      <Sider width="200px">
        <Menu mode="vertical" defaultActiveFirst onClick={handleMenuClick}>
          {menuItems.map((item, index) => (
            <CanAccess
              key={item.key}
              resource={item.name.toLowerCase()}
              action="list"
              params={{
                resource: item,
              }}
            >
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.route ?? ""}>{item.label}</Link>
              </Menu.Item>
            </CanAccess>
          ))}
        </Menu>
      </Sider>
      <Layout style={{ padding: "0 20px" }}>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};
export default SettingsLayout;
