import { useList, useNavigation, useTranslate } from "@refinedev/core";
import { Button, Radio, Space } from "antd";
import React from "react";
import useTeamFn from "../../hooks/useTeamFn";
import { currencySymbols, formatCurrency } from "../../utility";
import CustomRadio from "../form/customRadioButton";

type Props = {
  handleOnChange: (item: any) => void;
  team?: any;
  disabled?: boolean;
  handleNext: () => void;
  handlePrev: () => void;
};
export default function SelectSubscription({
  handleOnChange,
  handleNext,
  handlePrev,
  disabled,
  team,
}: Props) {
  const [choosenPlan, setChoosenPlan] = React.useState<any>();

  const { data } = useList({
    resource: "subscription_offerings",
    meta: {
      schema: "basejump",
    },
  });
  const offerings = data?.data;
  const { push } = useNavigation();
  const { createTeam, isLoading } = useTeamFn();
  const translate = useTranslate();

  const handleFinish = async () => {
    try {
      await createTeam({ name: team }).then((data) => {
        push(`/${data.slug}`);
      });
    } catch (error) {
      console.log("Error while creating team");
    }
  };
  return (
    <Space direction="vertical">
      <Radio.Group
        buttonStyle="outline"
        style={{ width: "100%" }}
        disabled={disabled}
        onChange={(item) => {
          handleOnChange(item.target.value);
          setChoosenPlan(item.target.value);
        }}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          {offerings &&
            offerings
              ?.sort((sortItem) => (sortItem.price == 0 ? -1 : 0))
              .map((item) => (
                <CustomRadio
                  value={item}
                  key={item.id}
                  title={item.name}
                  disabled={disabled || item.price}
                  description={
                    !item.price
                      ? "29 days free"
                      : `${currencySymbols["EUR"]} ${formatCurrency(
                          item.price ?? 0
                        )} / ${item.interval ?? ""} ${translate(
                          "team.per_member"
                        )}`
                  }
                />
              ))}
        </Space>
      </Radio.Group>
      <Space>
        <Button onClick={() => handlePrev()} disabled={disabled}>
          Previous
        </Button>
        {choosenPlan?.price != "test" ? (
          <Button
            type="primary"
            onClick={handleFinish}
            disabled={disabled}
            loading={isLoading}
          >
            {translate("teams.create_team_button")}
          </Button>
        ) : (
          <Button
            type="primary"
            onClick={() => handleNext()}
            disabled={disabled}
          >
            {translate("button.next")}
          </Button>
        )}
      </Space>
    </Space>
  );
}
