import { createClient } from "@refinedev/supabase";

const SUPABASE_URL = "https://srphjucafrvwzagjxuvw.supabase.co";
const SUPABASE_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InNycGhqdWNhZnJ2d3phZ2p4dXZ3Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTA1MTQwODAsImV4cCI6MjAyNjA5MDA4MH0.8EMIjenlc6o_trsEwHY8jRUBb3_9GcUGS3kj5RUCJak";


export const supabaseClient = createClient(SUPABASE_URL, SUPABASE_KEY, {
  db: {
    schema: "public",
  },
  auth: {
    persistSession: true,
  },
});

