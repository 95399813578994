import {
  ArrowRightOutlined,
  DeleteOutlined,
  PlusOutlined,
  SaveOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import {
  Button,
  DatePicker,
  Flex,
  Form,
  InputNumber,
  Select,
  Space,
  Spin,
  Switch,
  Table,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useProductSelect } from "../../hooks/useProductSelect";
import {
  BillingType,
  IPhase,
  IProduct,
} from "../../interfaces/phase.interface";
import { numberToCurrency } from "../../utility";
import { Text } from "../text";

type Props = {
  data?: any;
  handleChange: (props: any) => void;
  handlePhaseDelete: (props: any) => void;
  isSubscription?: boolean;
};
export default function SinglePhase({
  data,
  handleChange,
  handlePhaseDelete,
  isSubscription,
}: Props) {
  const [phase, setPhase] = useState<IPhase>(data);
  const [showSelectProduct, setShowSelectProduct] = useState<boolean>(false);
  const [initialTrigger, setInitialTrigger] = useState<boolean>(false);
  const [disableFromValue, setDisableFromValue] = useState<boolean>(
    typeof phase.duration?.from == "object" &&
      phase.duration?.from.unit == "start"
  );
  const [disableToValue, setDisableToValue] = useState<boolean>(
    typeof phase.duration?.to == "object" &&
      phase.duration?.to.unit == "forever"
  );
  const [newProduct, setNewProduct] = useState<IProduct>();
  const translate = useTranslate();
  const { selectProps, queryResult } = useProductSelect();

  useEffect(() => {
    setPhase(data);
  }, [data]);

  const handleQuantityChange = (index: number, value: number) => {
    const newProductItems = phase.product_items;
    newProductItems[index].quantity = value;
    setPhase({ ...phase, product_items: newProductItems });
  };
  const handleRangeChange = (value: dayjs.Dayjs[]) => {
    const newPhaseDuration = phase.duration;
    // newProductItems[index].quantity = value;
    if (newPhaseDuration) {
      newPhaseDuration.from = value[0].format("YYYY-MM-DD");
      newPhaseDuration.to = value[1].format("YYYY-MM-DD");
    }
    setPhase({ ...phase, duration: newPhaseDuration });
  };

  const handleSelectProduct = (e: any) => {
    const newProd = queryResult.data?.data.find((element) => element.id === e);
    if (newProd) {
      const prod: IProduct = {
        product: newProd.id as number,
        name: newProd.title,
        netto_price: newProd.netto_price,
        currency: newProd.currency as string,
        sales_tax: newProd.sales_tax as number,
        quantity: newProd.quantity || 1, // Provide a default value if quantity is optional
        billing_frequency: {
          unit: newProd.billing_interval, // Ensure this field exists in newProd
          value: newProd.invoice_interval, // Ensure this field exists in newProd
        },
      };
      setNewProduct(prod);
    }
  };
  function handleAddNewProduct() {
    if (newProduct) {
      const newProductItems = [...phase.product_items, newProduct];
      setPhase({ ...phase, product_items: newProductItems });
      setNewProduct(undefined);
      setShowSelectProduct(false);
    }
  }
  const handleDeleteProduct = (index: number) => {
    const newProductItems = phase.product_items.filter(
      (item, i) => i !== index
    );
    setPhase({ ...phase, product_items: newProductItems });
  };
  const setNestedProperty = (obj, path, value) => {
    let keys = path.split(".");
    let current = obj;

    while (keys.length > 1) {
      const [firstKey, ...restKeys] = keys;
      if (!current[firstKey]) {
        current[firstKey] = {};
      }
      current = current[firstKey];
      keys = restKeys;
    }

    current[keys[0]] = value;
  };
  const handleFieldChange = (field: string, value: any) => {
    const updatedPhase = { ...phase };
    setNestedProperty(updatedPhase, field, value);
    setPhase(updatedPhase);
  };

  useEffect(() => {
    if (initialTrigger)
      setTimeout(function () {
        handleChange(phase);
      }, 500);
    else setInitialTrigger(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase]);

  if (!phase) return <Spin />;
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Flex justify="space-between">
        <Space align="baseline">
          {isSubscription ? (
            <Space direction="vertical">
              <Form.Item
                style={{ marginBottom: 0 }}
                label={translate("subscriptions.fields.forever")}
              >
                <Switch
                  checkedChildren={translate("buttons.yes")}
                  unCheckedChildren={translate("buttons.no")}
                  loading={phase.duration?.to !== data.duration.to}
                  onChange={() => {
                    phase.duration &&
                      setPhase({
                        ...phase,
                        duration: {
                          from: phase.duration?.from,
                          to:
                            phase.duration.to == BillingType.Forever
                              ? phase.duration?.from
                              : BillingType.Forever,
                        },
                      });
                  }}
                  checked={phase.duration?.to == BillingType.Forever}
                />
              </Form.Item>
              {phase.duration?.to !== BillingType.Forever ? (
                <Form.Item>
                  <DatePicker.RangePicker
                    value={[
                      dayjs(phase.duration?.from as string),
                      dayjs(phase.duration?.to as string),
                    ]}
                    onChange={(dates: any) => {
                      handleRangeChange(dates);
                    }}
                  />
                </Form.Item>
              ) : (
                <>
                  <Form.Item>
                    <DatePicker value={dayjs(phase.duration?.from as string)} />
                  </Form.Item>
                </>
              )}
            </Space>
          ) : (
            <Space direction="vertical">
              <Text>{translate("subscriptions.list.duration")}:</Text>
              <Space align="baseline">
                <Form.Item>
                  <Select
                    style={{ minWidth: 100 }}
                    value={
                      typeof phase.duration?.from == "object" &&
                      phase.duration?.from?.unit
                        ? phase.duration?.from?.unit
                        : "day"
                    }
                    onChange={(value) => {
                      handleFieldChange("duration.from.unit", value);
                      if (value == "start") setDisableFromValue(true);
                      else setDisableFromValue(false);
                    }}
                  >
                    <Select.Option value={BillingType.Start}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Start}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Day}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Day}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Week}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Week}`
                      )}
                    </Select.Option>
                    <Select.Option value={BillingType.Month}>
                      {translate(
                        `subscription_products.fields.billing_type.${BillingType.Month}`
                      )}
                    </Select.Option>
                  </Select>
                </Form.Item>
                {!disableFromValue && (
                  <Form.Item>
                    <InputNumber
                      disabled={disableFromValue}
                      min={1}
                      onChange={(value) =>
                        handleFieldChange("duration.from.value", value)
                      }
                      value={
                        typeof phase.duration?.from == "object" &&
                        phase.duration?.from?.value
                          ? phase.duration?.from?.value
                          : 1
                      }
                    />
                  </Form.Item>
                )}
                <ArrowRightOutlined />
                <Space>
                  <Form.Item>
                    <Select
                      style={{ minWidth: 100 }}
                      value={
                        typeof phase.duration?.to == "object" &&
                        phase.duration?.to?.unit
                          ? phase.duration?.to?.unit
                          : "day"
                      }
                      onChange={(value) => {
                        handleFieldChange("duration.to.unit", value);
                        if (value == "forever") setDisableToValue(true);
                        else setDisableToValue(false);
                      }}
                    >
                      <Select.Option value={BillingType.Day}>
                        {translate(
                          `subscription_products.fields.billing_type.${BillingType.Day}`
                        )}
                      </Select.Option>
                      <Select.Option value={BillingType.Week}>
                        {translate(
                          `subscription_products.fields.billing_type.${BillingType.Week}`
                        )}
                      </Select.Option>
                      <Select.Option value={BillingType.Month}>
                        {translate(
                          `subscription_products.fields.billing_type.${BillingType.Month}`
                        )}
                      </Select.Option>
                      <Select.Option value={BillingType.Forever}>
                        {translate(
                          `subscription_products.fields.billing_type.${BillingType.Forever}`
                        )}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {!disableToValue && (
                    <Form.Item>
                      <InputNumber
                        disabled={disableToValue}
                        min={1}
                        value={
                          typeof phase.duration?.to == "object" &&
                          phase.duration?.to?.value
                            ? phase.duration?.to?.value
                            : 1
                        }
                        onChange={(value) =>
                          handleFieldChange("duration.to.value", value)
                        }
                      />
                    </Form.Item>
                  )}
                </Space>
              </Space>
            </Space>
          )}
        </Space>
        <Space direction="vertical">
          <Text>{translate("product_items.fields.invoice_interval")}:</Text>
          <Space>
            <Form.Item>
              <InputNumber
                min={1}
                value={phase.invoice.value ? phase.invoice.value : 1}
                onChange={(value) =>
                  handleFieldChange("duration.invoice.value", value)
                }
              />
            </Form.Item>
            <Form.Item>
              <Select
                style={{ minWidth: 100 }}
                value={phase.invoice.unit ? phase.invoice.unit : "day"}
                onChange={(value) =>
                  handleFieldChange("duration.invoice.unit", value)
                }
              >
                <Select.Option value={BillingType.Day}>
                  {translate(
                    `subscription_products.fields.billing_type.${BillingType.Day}`
                  )}
                </Select.Option>
                <Select.Option value={BillingType.Week}>
                  {translate(
                    `subscription_products.fields.billing_type.${BillingType.Week}`
                  )}
                </Select.Option>
                <Select.Option value={BillingType.Month}>
                  {translate(
                    `subscription_products.fields.billing_type.${BillingType.Month}`
                  )}
                </Select.Option>
                <Select.Option value={BillingType.Forever}>
                  {translate(
                    `subscription_products.fields.billing_type.${BillingType.Forever}`
                  )}
                </Select.Option>
              </Select>
            </Form.Item>
          </Space>
        </Space>
      </Flex>
      <Table
        pagination={false}
        footer={() => (
          <>
            <Flex justify="space-between" style={{ width: "100%" }}>
              <Space>
                {showSelectProduct && (
                  <>
                    <Select
                      {...selectProps}
                      onSelect={handleSelectProduct}
                      style={{ width: 300 }}
                    />
                    <Button
                      type="default"
                      icon={<StopOutlined />}
                      onClick={() => {
                        setShowSelectProduct(false);
                        setNewProduct(undefined);
                      }}
                    >
                      {translate("buttons.cancel")}
                    </Button>
                  </>
                )}
                <Button
                  type={showSelectProduct ? "primary" : "link"}
                  icon={showSelectProduct ? <SaveOutlined /> : <PlusOutlined />}
                  onClick={() =>
                    showSelectProduct
                      ? handleAddNewProduct()
                      : setShowSelectProduct(true)
                  }
                >
                  {showSelectProduct
                    ? translate("buttons.save")
                    : translate("product_items.buttons.add_product")}
                </Button>
              </Space>
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={handlePhaseDelete}
              >
                {translate("subscription_products.show.delete_phase")}
              </Button>
            </Flex>
          </>
        )}
        dataSource={phase?.product_items.map((item: any, index: number) => {
          return {
            key: (index + 1).toString(), // key should be a string
            product_item: (
              <Space direction="vertical">
                <Text>{item.name}</Text>
                <Text style={{ color: "rgba(140, 140, 140, 1)" }}>
                  {numberToCurrency(
                    item.netto_price,
                    item.currency.toLowerCase()
                  )}
                </Text>
              </Space>
            ),
            qty: (
              <InputNumber
                value={item.quantity}
                min={1}
                onChange={(value) => handleQuantityChange(index, value)}
              />
            ),
            total: numberToCurrency(
              item.netto_price * item.quantity,
              item.currency.toLowerCase()
            ),
            billing_frequency: {
              unit: item.billing_frequency.unit,
              value: item.billing_frequency.value,
            },
            action: (
              <Button
                type="link"
                onClick={() => handleDeleteProduct(index)}
                icon={<DeleteOutlined />}
                danger
              />
            ),
          };
        })}
      >
        <Table.Column
          dataIndex={"product_item"}
          title={translate("product_items.show.title")}
        />
        <Table.Column
          title={translate("product_items.show.qty")}
          dataIndex={"qty"}
        />
        <Table.Column
          title={translate("product_items.show.billing_frequency")}
          dataIndex={"billing_frequency"}
          render={(value) => (
            <>
              {value.value},{" "}
              {translate(
                `product_items.fields.billing_interval_enum.${value.unit}`
              )}
            </>
          )}
        />
        <Table.Column
          title={translate("product_items.show.total")}
          dataIndex={"total"}
        />
        <Table.Column
          title={translate("product_items.show.action")}
          dataIndex={"action"}
        />
      </Table>
    </Space>
  );
}
