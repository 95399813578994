import {
  IResourceComponentsProps,
  useLink,
  useNavigation,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Breadcrumb, Button, Card, Col, Form, Row, Space, Spin } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

import { ApartmentOutlined } from "@ant-design/icons";

import { Editor } from "@monaco-editor/react";
import { List, useForm } from "@refinedev/antd";
import { Text } from "../../components";
import { useUser } from "../../contexts/ContextProvider";

const PipelineCustomField: React.FC<IResourceComponentsProps> = () => {
  const params = useParams();
  const [customJson, setCustomJson] = React.useState<string>("");
  const [customUi, setCustomUi] = React.useState<any>("");
  const { currentTeam } = useUser();
  const { queryResult } = useShow({
    resource: "pipelines",
    id: params.pipelineId,
    meta: {
      select: "*",
    },
  });
  const { data, refetch, isLoading } = queryResult;

  const { formProps } = useForm({
    action: "edit",
    resource: "pipelines",
    id: params?.id,
    redirect: false,
    //mutationMode: "optimistic",
  });

  const record = data?.data;

  const { push } = useNavigation();
  const translate = useTranslate();
  const [openModal, setOpenModal] = useState<{
    id?: number | string;
    open: boolean;
  }>();
  const handleModalSuccess = () => {
    setOpenModal({ open: false });
    refetch();
  };

  React.useEffect(() => {
    if (record?.custom_fields_json)
      setCustomJson(JSON.stringify(record?.custom_fields_json, null, 2));
    if (record?.custom_fields_ui)
      setCustomUi(JSON.stringify(record?.custom_fields_ui, null, 2));
  }, [record?.custom_fields_json, record?.custom_fields_ui]);
  const Link = useLink();
  if (!record) {
    return <Spin />;
  }
  return (
    <>
      <List
        title={translate("pipelines.titles.edit")}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link
                to={`/${currentTeam.slug}/pipelines/show/${params?.pipelineId}`}
              >
                <ApartmentOutlined /> {record?.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/${currentTeam.slug}/pipelines/show/${params?.pipelineId}/settings`}
              >
                {translate("settings.settings")}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {translate("buttons.custom_fields", "Config Custom Fields")}
            </Breadcrumb.Item>
          </Breadcrumb>
        }
      >
        <Space direction="vertical" size={"large"} style={{ width: "100%" }}>
          {!isLoading && (
            <>
              <Form
                {...formProps}
                style={{ marginTop: 20 }}
                onFinish={() => {
                  const parsedValues = {
                    custom_fields_json:
                      formProps.form?.getFieldValue("custom_fields_json"),
                    custom_fields_ui:
                      formProps.form?.getFieldValue("custom_fields_ui"),
                  };
                  formProps.onFinish && formProps.onFinish(parsedValues);
                }}
              >
                <Row gutter={12}>
                  <Col span={12}>
                    <Card
                      title={<Text>{translate("settings.custom_json")}:</Text>}
                    >
                      <Editor
                        height="40vh"
                        defaultLanguage="json"
                        loading={"Loading..."}
                        value={customJson}
                        onChange={(e) => {
                          formProps.form?.setFieldValue(
                            "custom_fields_json",
                            JSON.parse(e ?? "")
                          );
                        }}
                      />
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      title={<Text>{translate("settings.field_json")}:</Text>}
                    >
                      <Editor
                        height="40vh"
                        defaultLanguage="json"
                        loading={"Loading..."}
                        value={customUi}
                        onChange={(e) => {
                          formProps.form?.setFieldValue(
                            "custom_fields_ui",
                            JSON.parse(e ?? "")
                          );
                        }}
                      />
                    </Card>
                  </Col>
                </Row>
                <Row gutter={12} style={{ marginTop: 20 }}>
                  <Button type="primary" htmlType="submit">
                    {translate("buttons.save")}
                  </Button>
                </Row>
              </Form>
            </>
          )}
        </Space>
      </List>
    </>
  );
};
export default PipelineCustomField;
