import { AuthBindings } from "@refinedev/core";

import { jwtDecode } from "jwt-decode";
import { supabaseClient } from "./utility";

const authProvider: AuthBindings = {
  login: async ({ email, password, providerName }) => {
    // sign in with oauth
    try {
      if (providerName) {
        const { data, error } = await supabaseClient.auth.signInWithOAuth({
          provider: providerName,
        });

        if (error) {
          return {
            success: false,
            error,
          };
        }

        if (data?.url) {
          return {
            success: true,
            redirectTo: "/",
          };
        }
      }

      // sign in with email and password
      const { data, error } = await supabaseClient.auth.signInWithPassword({
        email,
        password,
      });
      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data?.user) {
        return {
          success: true,
          redirectTo: "/",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: false,
      error: {
        message: "Login failed",
        name: "Invalid email or password",
      },
    };
  },
  register: async ({ email, password, ...others }) => {
    try {
      const { data, error } = await supabaseClient.auth.signUp({
        email,
        password,
        options: {
          data: {
            ...others,
          },
        },
      });

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (
        data &&
        data.user?.user_metadata &&
        data.user?.identities &&
        Object.keys(data.user.user_metadata).length > 0 &&
        data.user?.identities.length > 0
      ) {
        return {
          success: true,
          successNotification: {
            message: "Registred succesfull",
            description:
              "The signup was successfull. Please check your email for verification!",
            type: "success",
          },
          redirectTo: "/",
        };
      } else {
        return {
          success: false,
          successNotification: {
            message: "Email already exists",
            description:
              "Please try with another email as this one is registred. Or try forget password!",
          },
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }
  },
  forgotPassword: async ({ email }) => {
    try {
      const { data, error } = await supabaseClient.auth.resetPasswordForEmail(
        email,
        {
          redirectTo: `${window.location.origin}/update-password`,
        }
      );

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        return {
          success: true,
          successNotification: {
            key: "forget-password-success",
            message: "Request succesfull",
            description: "The request for this email was send succesfully",
            type: "success",
          },
          redirectTo: "/",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
        successNotification: {
          key: "forget-password-error",
          message: "Request was not succesfull",
          description: "Something went wrong, please check your email address!",
          type: "error",
        },
      };
    }

    return {
      success: false,
      error: {
        message: "Forgot password failed",
        name: "Invalid email",
      },
    };
  },
  updatePassword: async ({ password }) => {
    try {
      const { data, error } = await supabaseClient.auth.updateUser({
        password,
      });

      if (error) {
        return {
          success: false,
          error,
        };
      }

      if (data) {
        return {
          success: true,
          redirectTo: "/",
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      };
    }
    return {
      success: false,
      error: {
        message: "Update password failed",
        name: "Invalid password",
      },
    };
  },
  logout: async () => {
    const { error } = await supabaseClient.auth.signOut();

    if (error) {
      return {
        success: false,
        error,
      };
    }

    return {
      success: true,
      redirectTo: "/",
    };
  },
  onError: async (error) => {
    return { error };
  },
  check: async () => {
    try {
      const { data } = await supabaseClient.auth.getSession();
      const { session } = data;

      if (!session) {
        return {
          authenticated: false,
          error: {
            message: "Check failed",
            name: "Session not found",
          },
          logout: true,
          redirectTo: "/login",
        };
      }
    } catch (error: any) {
      return {
        authenticated: false,
        error: error || {
          message: "Check failed",
          name: "Not authenticated",
        },
        logout: true,
        redirectTo: "/login",
      };
    }

    return {
      authenticated: true,
    };
  },
  getPermissions: async () => {
    const session = await supabaseClient.auth.getSession();

    if (session?.data.session) {
      return jwtDecode<any>(session.data.session?.access_token);
    }

    return null;
  },
  getIdentity: async () => {
    const { data } = await supabaseClient.auth.getUser();

    if (data?.user) {
      return {
        ...data.user,
        name: data.user.email,
      };
    }

    return null;
  },
};

export default authProvider;
