import { CloseOutlined } from "@ant-design/icons";
import { useTranslate } from "@refinedev/core";
import { Button, Flex, Typography } from "antd";
import { Images } from "../../components/Images";
import CreateSteper from "../../components/steps/createTeam";
import styles from "./style.module.css";

export default function CreateTeam() {
  const translate = useTranslate();
  return (
    <Flex className={styles.container}>
      <div className={styles.stepperContainer}>
        <CreateSteper />
      </div>
      <div className={styles.imgContainer}>
        <img src={Images.TeamCover} />
      </div>
      <div className={styles.titleStrip}>
        <Typography.Title>
          {translate("teams.create_new_team")}
        </Typography.Title>
        <Button
          type="text"
          onClick={() => (location.href = "/")}
          icon={<CloseOutlined className={styles.closeButton} />}
        ></Button>
      </div>
    </Flex>
  );
}
