import {
  useThemedLayoutContext,
  type RefineThemedLayoutV2HeaderProps,
} from "@refinedev/antd";
import { Layout as AntdLayout, Button, Flex, Grid } from "antd";

import { LayoutOutlined } from "@ant-design/icons";
import React from "react";
import { useUser } from "../../contexts/ContextProvider";
import { Text } from "../text";
// import { ColorModeContext } from "../../contexts/color-mode";

export const HeaderV2: React.FC<RefineThemedLayoutV2HeaderProps> = ({
  sticky,
}) => {
  const breakpoint = Grid.useBreakpoint();
  const { currentTeam } = useUser();
  const { setMobileSiderOpen } = useThemedLayoutContext();
  const isMobile =
    typeof breakpoint.lg === "undefined" ? false : !breakpoint.lg;
  if (!isMobile) {
    return <AntdLayout.Header style={{ height: 20 }}></AntdLayout.Header>;
  }
  return (
    <AntdLayout.Header style={{ padding: 15 }}>
      <Flex align="center">
        <Button
          type="text"
          size="large"
          onClick={() => setMobileSiderOpen(true)}
          icon={<LayoutOutlined style={{ fontSize: 23 }} />}
        />
        <Text size="md">/ {currentTeam?.name}</Text>
      </Flex>
    </AntdLayout.Header>
  );
};
