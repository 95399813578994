import { Divider, Input, Typography, theme } from "antd";
import { InputProps } from "antd/lib/input";

type Props = {
  label?: string;
} & InputProps;
export default function CustomInput({ label, ...props }: Props) {
  const { token } = theme.useToken();

  return (
    <>
      <Input
        {...props}
        variant="borderless"
        size="large"
        style={{ fontSize: "30px" }}
      />
      <Divider
        style={{
          borderColor: props.disabled
            ? token.colorTextDisabled
            : token.colorPrimary,
          marginBlock: "5px",
        }}
      />
      {label && (
        <Typography.Text
          disabled={props.disabled}
          style={{
            color: props.disabled
              ? token.colorTextDisabled
              : token.colorPrimary,
          }}
        >
          {label}
        </Typography.Text>
      )}
    </>
  );
}
