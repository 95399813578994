import React, { PropsWithChildren, createContext, useState } from "react";

type PipelineContextType = {
  pipelines: any[];
  setPipelines: React.Dispatch<React.SetStateAction<any>>;
  rawPipelines: any[];
  setRawPipelines: React.Dispatch<React.SetStateAction<any>>;
};
export const PipelineContext = createContext<PipelineContextType>({
  pipelines: [],
  setPipelines: () => [],
  rawPipelines: [],
  setRawPipelines: () => [],
});

export const PipelineProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [pipelines, setPipelines] = useState([]);
  const [rawPipelines, setRawPipelines] = useState([]);

  return (
    <PipelineContext.Provider
      value={{ pipelines, setPipelines, rawPipelines, setRawPipelines }}
    >
      {children}
    </PipelineContext.Provider>
  );
};
